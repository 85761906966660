/*------------------buttons--------------------------*/
.btn {
  border-radius: 30px;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 700;
  overflow: hidden;
  line-height: 1;
  padding: 12px 32px;
  margin: 12px;
  position: relative;
}
.btn:focus {
  box-shadow: none;
  outline: 0;
}

@keyframes button-push {
  50% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes hvr-push {
  50% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.btn-default {
  color: #fff !important;
  background: blue;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.btn-default:hover {
  color: #fff;
  -webkit-animation-name: button-push;
  animation-name: button-push;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

.btn-full {
  text-align: center;
  width: 100%;
}

.btn-group-lg > .btn,
.btn-lg {
  padding: 28px 30px;
  font-size: 18px;
  font-weight: 100;
  line-height: 0;
}

.btn-sm {
  padding: 17px 16px;
  font-size: 12px;
  line-height: 0;
}

.btn-xs {
  padding: 12px 10px;
  font-size: 12px;
  line-height: 0;
}

.btn.disabled,
.btn:disabled {
  opacity: 1;
  cursor: not-allowed;
  background-color: grey;
}

.btn-box {
  position: relative;
}
