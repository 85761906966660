@-webkit-key frames spin-around {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin-around {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
/*------------------sections---------------------*/
main.content {
  margin-left: 290px;
  margin-right: 290px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
main.content.open {
  opacity: 0.8;
  -webkit-transform: translateX(290px);
  -moz-transform: translateX(290px);
  -ms-transform: translateX(290px);
  -o-transform: translateX(290px);
  transform: translateX(290px);
}

section {
  padding-top: 110px;
  position: relative;
}

section.home {
  background: #ffffff;
  padding: 0;
  height: 100vh;
  min-height: 100vh;
}
.section-title {
  font-size: 36px;
  margin: 0;
  margin-left: 14px;
  position: relative;
}
.section-title::before {
  content: "";
  display: block;
  height: 37px;
  left: -14px;
  top: -14px;
  position: absolute;
  width: 37px;
}

.section-title-div h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: #5e84ff;
  margin-bottom: 15px;
  text-transform: uppercase;
}

.section-title-div h3 img {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: -1px;
}

@media only screen and (max-width: 1199px) {
  .section-title-div h3 {
    margin-bottom: 10px;
  }

  .section-title-div h1 {
    font-size: 40px;
    line-height: 1.35;
  }
}

@media only screen and (max-width: 991px) {
  .section-title-div h3 {
    margin-bottom: 5px;
  }

  .section-title-div h1 {
    font-size: 34px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title-div h3 {
    font-size: 16px;
  }
  .section-title-div h1 {
    font-size: 30px;
    line-height: 45px;
  }
}

@media only screen and (max-width: 575px) {
  .section-title-div h1 {
    font-size: 25px;
  }
}
/*---------------------------boxes----------------------------*/
.custom-box {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.custom-box h3 {
  font-size: 20px;
}
.custom-box img {
  margin-bottom: 22px;
}
.custom-box:hover {
  border-color: transparent;
  -webkit-transform: translateY(-10px);
  -ms-transform: translateY(-10px);
  transform: translateY(-10px);
}
.custom-box::after {
  opacity: 1;
  animation: spin-around 9s linear infinite;
}
/*-----------------------helper------------------------------*/
.help-block.with-errors {
  color: red;
  font-size: 12px;
  padding-left: 30px;
}
.help-block.with-errors ul {
  margin-bottom: 0;
}

.spacer {
  clear: both;
}
.spacer[data-height="60"] {
  height: 60px;
}
.spacer[data-height="70"] {
  height: 70px;
}
.spacer[data-height="30"] {
  height: 30px;
}
.spacer[data-height="96"] {
  height: 96px;
}
.spacer[data-height="20"] {
  height: 20px;
}

.text-link {
  font-family: "Rubik", sans-serif;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
}

.fa-spinner {
  margin-right: 10px;
  display: none;
}

.scroll-down {
  position: absolute;
  top: -120px;
  left: 0;
  width: 100%;
  z-index: 1;
}
.scroll-down button {
  background: transparent;
  outline: 0;
  border: 0;
  cursor: pointer;
}
.scroll-down.light span {
  color: #454360;
}
.scroll-down.light .mouse {
  border-color: #fff;
}
.scroll-down.light .mouse .wheel {
  background: #fff;
}

@-webkit-keyframes ani-mouse {
  0% {
    top: 25%;
  }
  15% {
    top: 50%;
  }
  50% {
    top: 50%;
  }
  100% {
    top: 25%;
  }
}
@-moz-keyframes ani-mouse {
  0% {
    top: 25%;
  }
  15% {
    top: 50%;
  }
  50% {
    top: 50%;
  }
  100% {
    top: 25%;
  }
}
@keyframes ani-mouse {
  0% {
    top: 25%;
  }
  15% {
    top: 50%;
  }
  50% {
    top: 50%;
  }
  100% {
    top: 25%;
  }
}
.mouse-wrapper {
  position: relative;
  top: 25px;
  color: #fff;
  font-size: 16px;
  display: block;
  max-width: 100px;
  margin: auto;
  text-align: center;
  cursor: pointer;
}
.mouse-wrapper span {
  color: #ffffff;
}
.mouse-wrapper:hover {
  color: #fff;
}

.mouse {
  border: solid 2px #fff;
  border-radius: 16px;
  display: block;
  margin: auto;
  margin-top: 10px;
  height: 26px;
  position: relative;
  width: 20px;
}
.mouse .wheel {
  background: #fff;
  border-radius: 100%;
  display: block;
  position: absolute;
  top: 8px;
  left: 50%;
  transform: translateX(-50%);
  height: 4px;
  width: 4px;
  -webkit-animation: ani-mouse 2s linear infinite;
  -moz-animation: ani-mouse 2s linear infinite;
  animation: ani-mouse 2s linear infinite;
}

.circle {
  border-radius: 100%;
}

footer.footer {
  padding: 40px 0;
  text-align: center;
}
footer.footer .copyright {
  color: #9c9ab3;
  font-size: 14px;
}
footer.footer.light {
  background: #f9f9ff;
  border-top: solid 1px rgba(0, 0, 0, 0.05);
}
