@-webkit-keyframes back-to-top-bounce {
  0% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-5px);
  }
  50% {
    -webkit-transform: translateY(8px);
    transform: translateY(10px);
  }
  100% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-5px);
  }
}

@keyframes back-to-top-bounce {
  0% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-5px);
  }
  50% {
    -webkit-transform: translateY(8px);
    transform: translateY(10px);
  }
  100% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-5px);
  }
}

@-webkit-keyframes rotate-icon {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes rotate-icon {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes hvr-icon-pulse-grow {
  to {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }
}
@keyframes hvr-icon-pulse-grow {
  to {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }
}

@-webkit-keyframes hvr-icon-buzz-out {
  10% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  20% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  30% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  40% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  50% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }
  60% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }
  70% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }
  80% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }
  90% {
    -webkit-transform: translateX(1px) rotate(0);
    transform: translateX(1px) rotate(0);
  }
  100% {
    -webkit-transform: translateX(-1px) rotate(0);
    transform: translateX(-1px) rotate(0);
  }
}
@keyframes hvr-icon-buzz-out {
  10% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  20% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  30% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  40% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  50% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }
  60% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }
  70% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }
  80% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }
  90% {
    -webkit-transform: translateX(1px) rotate(0);
    transform: translateX(1px) rotate(0);
  }
  100% {
    -webkit-transform: translateX(-1px) rotate(0);
    transform: translateX(-1px) rotate(0);
  }
}
/*----------------------reset css-------------------------------------*/
html {
  overflow-y: auto;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

body {
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font: 17px/32px "Roboto", sans-serif;
  color: #7d7b7f;
  font-weight: 400;
  overflow: hidden;
}

a,
b,
body,
details,
div,
footer,
header,
h1,
h2,
h3,
h4,
h5,
h6,
html,
i,
img,
li,
menu,
nav p,
section,
span,
textarea,
ul {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  font-style: inherit;
  vertical-align: baseline;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  background: transparent;
}

article,
details,
footer,
header,
menu,
nav,
section {
  display: block;
}

ul {
  list-style: none;
}

img {
  border: 0;
  -ms-interpolation-mode: bicubic;
  vertical-align: middle;
}

button,
input,
textarea {
  font-size: 100%;
  margin: 0;
  max-width: 100%;
  vertical-align: baseline;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

button,
input {
  line-height: normal;
}

input,
textarea {
  background-image: -webkit-linear-gradient(
    hsla(0, 0%, 100%, 0),
    hsla(0, 0%, 100%, 0)
  );
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

a {
  font-size: 15px;
  text-decoration: none;
  color: #3b4250;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

a:hover,
a:focus {
  color: #3742d2;
  text-decoration: none;
  outline: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

ul {
  padding: 0;
}

img {
  max-width: 100%;
  height: auto;
}

b {
  font-weight: 900;
}

button {
  border: none;
}

button,
input[type="submit"] {
  display: inline-block;
  padding: 18px 39px 23px 40px;
  line-height: 24px;
  font-size: 16px;
  text-transform: capitalize;
  color: #fff;
  background: #8b2ee1;
  position: relative;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

button:hover input[type="submit"]:hover {
  color: #fff;
  background: #3742d2;
}

textarea,
input[type="text"],
input[type="email"] {
  position: relative;
  display: block;
  width: 100%;
  line-height: 24px;
  padding: 0px 17px;
  color: #333333;
  font-size: 17px;
  font-weight: 400;
  border: 1px solid #e8ebf6;
  height: 70px;
  background-color: #fff;
  border-radius: 5px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

textarea:focus,
input[type="text"]:focus,
input[type="email"]:focus {
  border: 1px solid #374cd7;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

textarea {
  width: 100%;
  padding: 17px 17px;
  height: 100px;
}

textarea:-moz-placeholder,
textarea::-moz-placeholder,
input:-moz-placeholder,
input::-moz-placeholder {
  color: #aeacca;
  opacity: 1;
}

input:-ms-input-placeholder {
  color: #aeacca;
}

textarea::-webkit-input-placeholder,
input::-webkit-input-placeholder {
  color: #aeacca;
  opacity: 1;
}

/*----------------------hover-------------------------------------*/
#bottom .socials-list a:hover,
.widget-nav-menu li a:hover {
  color: #3154f1;
}
.header-style #mainnav .menu li a {
  color: #fff;
}
.header-style #mainnav .menu li a:hover,
.header-style #mainnav .menu li a.active {
  color: #3154f1;
  text-decoration: underline;
  text-underline-position: under;
}

.github-iframe-repo {
  padding-top: 14px;
  margin-left: -250px;
}
/*----------------------padding-------------------------------------*/
.pd-l50 {
  padding-left: 50px;
}

/*----------------------letter spacing------------------------------*/
.lt-sp02 {
  letter-spacing: 0.2px;
}
.lt-sp04 {
  letter-spacing: 0.4px;
}
.lt-sp08 {
  letter-spacing: 0.8px;
}
.lt-sp53 {
  letter-spacing: 5.3px;
}

/* ---------------------------header------------------------------------- */
#header {
  width: 100%;
  position: fixed;
  z-index: 999;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  font-family: "Roboto", sans-serif;
}

.header-style.header-fixed {
  background-color: #111527;
  -webkit-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.2);
  -ms-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.2);
  -o-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.2);
}

.header-style.header-fixed #mainnav .menu > li > a {
  line-height: 80px;
}
.header-style.header-shadow {
  background-color: #fff;
}
.header-style.header-shadow {
  background-color: #000;
}
.header-style.header-shadow {
  -webkit-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.2);
  -ms-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.2);
  -o-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.2);
}
.header-style.header-shadow #mainnav .menu li a {
  color: #23233f;
}

.header-style.header-shadow #mainnav .menu > li > a {
  color: #fff;
}
.header-style.header-shadow #mainnav .menu > li > a {
  line-height: 80px;
}

/* ---------------------------menu------------------------------------- */
#mainnav .menu li a {
  font-size: 17px;
  color: #dcc2f3;
  font-weight: 700;
}
#mainnav .menu > li {
  display: inline-block;
  position: relative;
}
#mainnav .menu > li > a {
  line-height: 102px;
  display: inline-block;
  padding-right: 25px;
  letter-spacing: 0.8px;
  position: relative;
}
#mainnav .menu > li > a span {
  position: relative;
}
#mainnav .menu > li > a span:after {
  content: "";
  width: 0;
  height: 1px;
  bottom: 0;
  position: absolute;
  left: auto;
  right: 0;
  z-index: -1;
  -webkit-transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  -o-transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  background: currentColor;
}
#mainnav .menu > li:hover > a span:after {
  width: 100%;
  left: 0;
  right: auto;
}
#mainnav .menu > li:last-child > a {
  padding-right: 0;
}
#mainnav .menu > li > .sub-menu {
  opacity: 0;
  z-index: 9999;
  position: absolute;
  width: 220px;
  background-color: #ffffff;
  border-radius: 5px;
  top: 150%;
  left: 0;
  visibility: hidden;
  -webkit-box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
#mainnav .menu > li:last-child .sub-menu {
  left: auto;
  right: 0;
}
#mainnav .menu > li:last-child .sub-menu:before {
  right: 20px;
  left: auto;
}
#mainnav .menu > li:hover > .sub-menu {
  opacity: 1;
  z-index: 1;
  top: 100%;
  visibility: visible;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
#mainnav .menu > li > .sub-menu:before {
  content: "";
  top: -7px;
  left: 20px;
  z-index: -1;
  width: 15px;
  height: 15px;
  position: absolute;
  background: #ffffff;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
#mainnav .menu > li > .sub-menu > li {
  margin: 0 20px;
  border-bottom: 1px solid rgba(16, 32, 57, 0.1);
}
#mainnav .menu > li > .sub-menu > li:last-child {
  border-bottom: none;
}
#mainnav .menu > li > .sub-menu > li > a {
  display: block;
  color: #102039;
  line-height: 52px;
}
#mainnav .menu > li > .sub-menu > li > a.active,
#mainnav .menu > li > .sub-menu > li > a:hover {
  margin-left: 8px;
}
.btn-menu {
  display: none;
  margin: 25px 0 25px 25px;
}
.btn-menu .line {
  height: 1px;
  margin: 7px 0;
  background: #fff;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.btn-menu .line.line-1 {
  width: 30px;
}
.btn-menu .line.line-2 {
  width: 36px;
}
.btn-menu .line.line-3 {
  width: 28px;
}
.btn-menu .line.line-4 {
  width: 32px;
}
.btn-menu:hover .line {
  width: 36px;
  cursor: pointer;
}
.btn-menu:hover {
  width: 36px;
  cursor: pointer;
}
.btn-general a,
.btn-general button {
  display: inline-block;
  font-size: 17px;
}
/* ---------------------------banner------------------------------------- */

.disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.6;
}

.coming-soon-text {
  margin-bottom: 10px;
  font-size: 8px;
  color: blue;
  font-weight: 600;
  border-radius: 5px;
}

.banner-section {
  padding: 220px 0 100px;
  background: url("../../public/images/backgrounds/main.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.banner-section .heading-top {
  font-size: 33px;
}

.banner-section .person-front {
  margin-bottom: 120px;
}

.banner-section .person-front img:first-child {
  box-shadow: 0 10px 40px rgb(0 0 0 0.07%);
  border-radius: 10px;
  max-width: 120%;
}

.banner-section .person-front img:last-child {
  position: absolute;
  right: 5%;
  bottom: -30%;
  height: 80%;
  z-index: 1;
}

.banner-content.banner-content-inner .banner-title {
  margin-top: 10px;
  font-weight: 700;
  font-size: 70px;
  line-height: 80px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .banner-content.banner-content-inner .banner-title {
    font-size: 60px;
    line-height: 70px;
  }
}

@media (max-width: 991px) {
  .github-iframe-repo {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-content.banner-content-inner .banner-title {
    font-size: 50px;
    line-height: 60px;
  }
}
@media (max-width: 767px) {
  .banner-content.banner-content-inner .banner-title {
    font-size: 36px;
    line-height: 46px;
  }
}
.banner-content.banner-content-inner ul li a {
  background: blue;
  border-color: blue;
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 400;
  padding-top: 10px;
  padding-bottom: 10px;
}
.banner-content.banner-content-inner ul li a span {
  font-size: 17px;
  font-weight: 700;
  display: block;
  line-height: 20px;
}
.banner-content.banner-content-inner ul li a i {
  font-size: 30px;
}

.banner-content.banner-content-inner ul li a:hover {
  background: #fff;
  color: blue;
  border-color: #fff;
}
.banner-content.banner-content-inner ul li a.banner-content-firefox-btn {
  background: transparent;
  border-color: #fff;
  color: #fff;
}
.banner-content.banner-content-inner ul li a.banner-content-firefox-btn:hover {
  background: blue;
  border-color: blue;
}
.banner-content.banner-content-inner .banner-title {
  color: #fff;
}
.banner-content.banner-content-inner p {
  color: #fff;
  opacity: 0.5;
}
.banner-content.banner-content-inner ul li a {
  background: #fff;
  color: blue;
  border-color: #fff;
}
.banner-content.banner-content-inner ul li a:hover {
  background: transparent;
  border-color: blue;
  color: #fff;
}
.banner-content.banner-content-inner ul li a.banner-content-google-btn {
  background: transparent;
  color: #fff;
  border-color: blue;
}
.banner-content.banner-content-inner a.banner-content-google-btn:hover {
  border-color: #fff;
  background: #fff;
  color: blue;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-content {
    margin-bottom: 50px;
    text-align: center;
  }
}
@media (max-width: 767px) {
  .banner-content {
    margin-bottom: 50px;
    text-align: center;
  }
}
.banner-content > span {
  color: #2b70fa;
  font-size: 14px;
  font-weight: 500;
}
.banner-content .banner-title {
  font-size: 60px;
  line-height: 70px;
  margin-top: 10px;
  margin-bottom: 22px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .banner-content .banner-title {
    font-size: 48px;
    line-height: 58px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-content .banner-title {
    font-size: 48px;
    line-height: 58px;
  }
}
@media (max-width: 767px) {
  .banner-content .banner-title {
    font-size: 28px;
    line-height: 38px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-content .banner-title {
    font-size: 36px;
    line-height: 46px;
  }
}
.banner-content p {
  font-size: 18px;
  line-height: 28px;
}
@media (max-width: 767px) {
  .banner-content p {
    font-size: 15px;
    line-height: 26px;
  }
}
.banner-content ul {
  margin: 41px 0 0;
  padding: 0;
  list-style-type: none;
}
.banner-content ul li {
  display: inline-block;
  margin-right: 8px;
}
@media (max-width: 767px) {
  .banner-content ul li {
    margin-bottom: 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-content ul li {
    margin-bottom: 0px;
  }
}
.banner-content ul li a {
  background: #0e1133;
  color: #fff;
  line-height: 40px;
  padding: 0 23px;
  border-radius: 6px;
  border: 2px solid blue;
}
.banner-content ul li a i {
  padding-right: 6px;
}
.banner-content ul li a:hover {
  background: transparent;
  color: blue;
}
.banner-content ul li a.banner-content-google-btn {
  background: transparent;
  color: blue;
}
.banner-content ul li a.banner-content-google-btn:hover {
  background: #0e1133;
  color: #fff;
}

.banner-section-image .thumb {
  position: relative;
  width: 150%;
  z-index: 10;
  right: 10%;
}

.app-live-circle-section {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 140px;
  width: 140px;
  border-radius: 50%;
  border: none;
  background: #ffffff;
  top: -160px;
  z-index: 11;
}
.app-live-circle-section .app-live-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.app-live-circle-section .app-live-circle .app-live-text {
  animation: rotate-icon 20s linear infinite;
  position: absolute;
}

@media (max-width: 992px) {
  .app-live-circle-section {
    display: none;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .before-after {
    margin-top: 40px;
  }
}
@media only screen and (max-width: 767px) {
  .before-after {
    margin-top: 40px;
  }
}

.banner-section-image .thumb .back-image {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -45%);
  width: 100%;
  z-index: -1;
}

.banner-section-image .thumb .front-image {
  transform: translate(14%, 5%);
  width: 78%;
}

/* --------------------------roadmap--------------------------------- */

.roadmap-section {
  padding-top: 132px;
  padding-bottom: 146px;
  position: relative;
  overflow: hidden;
  padding-bottom: 0px;
  background: url("../../public/images/backgrounds/main.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1;
  min-height: 914px;
  padding-bottom: 100px;
}

.roadmap-section .roadmap-divider {
  position: absolute;
  top: 0px;
  height: 100%;
}

.roadmap-section .roadmap-divider img {
  height: 100%;
}

.roadmap-section .roadmap-top {
  margin-left: 33px;
  margin-bottom: -55px;
}

.roadmap-section .roadmap-card-list {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 52px;
}

.roadmap-section .roadmap-card-list .roadmap-divider {
  position: absolute;
  top: 0px;
  background: #5e84ff;
}

.roadmap-section .roadmap-card-list .roadmap-card-item {
  position: relative;
  width: 95%;
}

.roadmap-section .roadmap-card-list .roadmap-card-item.roadmap-card-item-even {
  padding: 30px 0px 0px 30px;
  margin-top: 80px;
  margin-left: auto;
}

.roadmap-section
  .roadmap-card-list
  .roadmap-card-item.roadmap-card-item-even
  .roadmap-card-box {
  left: 0px;
  top: 0px;
}

.roadmap-section .roadmap-card-list .roadmap-card-item.roadmap-card-item-odd {
  padding: 30px 30px 0px 0px;
  margin: 0 auto 80px 0;
}

.roadmap-section
  .roadmap-card-list
  .roadmap-card-item.roadmap-card-item-odd
  .roadmap-card-box {
  right: 0px;
  top: 0px;
}

.roadmap-section .roadmap-card-list .roadmap-card-item .roadmap-card-box {
  background: #5e84ff;
  height: 30px;
  width: 30px;
  position: absolute;
}

.roadmap-section
  .roadmap-card-list
  .roadmap-card-item
  .roadmap-card-content:hover {
  cursor: pointer;
  background: radial-gradient(circle, rgba(84, 63, 179, 0.41), #1a1b34 35%);
}

.roadmap-section
  .roadmap-card-list
  .roadmap-card-item
  .roadmap-card-content:hover:before {
  opacity: 1;
  visibility: visible;
}

.roadmap-section .roadmap-card-list .roadmap-card-item .roadmap-card-content {
  background: #000;
  backdrop-filter: blur(10px);
  padding: 30px 40px;
}

.roadmap-section
  .roadmap-card-list
  .roadmap-card-item
  .roadmap-card-content
  h3 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 24px;
  line-height: 34px;
  color: #5e84ff;
  text-transform: uppercase;
  margin-bottom: 15px;
}

.roadmap-section
  .roadmap-card-list
  .roadmap-card-item
  .roadmap-card-content
  ul
  li {
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: rgba(255, 255, 255, 0.8);
  margin: 0;
}

.roadmap-section
  .roadmap-card-list
  .row:nth-child(2)
  .col-md-6
  .roadmap-card-item-even {
  margin-top: 100px;
}

@media only screen and (max-width: 767px) {
  .roadmap-card-list .roadmap-divider {
    display: none;
  }
  .roadmap-card-list .roadmap-card-item {
    margin: 0px;
    width: 100%;
    margin-bottom: 80px;
  }
  .roadmap-card-list .roadmap-card-item.roadmap-card-item-even {
    margin-top: 0px;
  }

  .roadmap-card-list .row:nth-child(2) .col-md-6 .roadmap-card-item-even {
    margin-top: 0;
  }
}

/*--------------------faq----------------------------- */
.faq-section {
  background: url("../../public/images/backgrounds/main.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.accordion-items {
  max-width: 740px;
  margin: 0 auto;
}
.accordion-items .accordion-item {
  margin-bottom: 21px;
  border-radius: 0;
  background: #000;
  color: #fff;
  box-shadow: none;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border: 2px solid #5e84ff;
  position: relative;
  z-index: 1;
  padding: 29px 23px 29px 19px;
}
.accordion-items .accordion-item:nth-last-child(1) {
  margin-bottom: 0;
}
.accordion-items .accordion-item::before,
.accordion-items .accordion-item::after {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  content: "";
  position: absolute;
  z-index: -1;
}
.accordion-items .accordion-item::before {
  width: calc(102% - 3rem);
  height: calc(100% + 4px);
  top: -2px;
  left: 50%;
  transform: translateX(-50%);
}
.accordion-items .accordion-item::after {
  height: calc(110% - 3rem);
  width: calc(100% + 4px);
  left: -2px;
  top: 50%;
  transform: translateY(-50%);
}
.accordion-items .accordion-item.active,
.accordion-items .accordion-item:hover {
  border-color: #5e84ff;
  box-shadow: 0 0px 32px 0 #5e84ff;
  cursor: pointer;
  background: radial-gradient(circle, rgba(84, 63, 179, 0.41), #1a1b34 35%);
}

.accordion-items .accordion-item.active,
.accordion-items .accordion-item:hover:hover:before {
  opacity: 0.7;
  visibility: visible;
}

.accordion-items .accordion-item.active::before,
.accordion-items .accordion-item:hover::before {
  width: 0 !important;
}
.accordion-items .accordion-item.active::after,
.accordion-items .accordion-item:hover::after {
  height: 0 !important;
}
.accordion-items .accordion-item .accordion-button {
  position: relative;
  background: transparent;
  transform: translateY(0);
  padding: 0;
  transition: all 0.3s ease;
  position: relative;
  z-index: 1;
  border-radius: 0 !important;
  color: #fff;
  box-shadow: none;
  font-size: 24px;
  line-height: 1.33;
  font-family: "Chakra Petch", sans-serif;
  font-weight: 700;
  text-transform: capitalize;
  border-bottom: 1px solid #5e84ff;
  padding-bottom: 15px;
  margin-bottom: 16px;
}
.accordion-items .accordion-item .accordion-button::after {
  background-image: none;
  content: "⤵";
  font-weight: 500;
  font-size: 25px;
  color: #5e84ff;
  position: absolute;
  top: 0;
  right: 0;
  width: auto;
  height: auto;
}
.accordion-items .accordion-item .accordion-button.collapsed {
  border-bottom: 1px solid rgba(3, 253, 203, 0);
  padding-bottom: 0;
  margin-bottom: 0;
}
.accordion-items .accordion-item .accordion-collapse {
  background: transparent;
}
.accordion-items .accordion-item .accordion-collapse .accordion-body {
  padding: 0;
}
.accordion-items .accordion-item.corner-box {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  padding: 28px 18px 11px;
}
.accordion-items .accordion-item.corner-box .toggle-title {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border-bottom: 1px solid #5e84ff;
  padding-bottom: 16px;
  margin-bottom: 0;
  position: relative;
  padding-right: 30px;
}
.accordion-items .accordion-item.corner-box .toggle-title:hover {
  cursor: context-menu;
}
.accordion-items .accordion-item.active.corner-box {
  padding: 28px 18px;
}
.accordion-items .accordion-item.active.corner-box .toggle-title {
  margin-bottom: 16px;
  border-bottom: 1px solid #5e84ff;
}

/*--------------------team----------------------------- */

.team-box {
  transition: all 0.3s ease;
  position: relative;
  padding: 0px 0 20px 0;
  margin-left: 445px;
  background: #000;
  width: 300px;
  overflow: hidden;
}

.team-box.active,
.team-box:hover {
  box-shadow: 0 0px 32px 0 #5e84ff;
}
.team-box.active::before,
.team-box:hover::before {
  border-color: #5e84ff;
  border: 2px solid #5e84ff;
}
.team-box .image {
  min-height: 310px;
  z-index: -2;
  padding: 2px;
}
.team-box .image img {
  max-height: 310px;
  width: 100%;
  height: 310px;
}
.team-box .content {
  position: relative;
  z-index: 10;
  padding: 16px 20px 0 20px;
}
.team-box .content .h8 {
  margin-bottom: 5px;
  line-height: 1.4;
  font-weight: 400;
  text-transform: uppercase;
}
.team-box .content p {
  margin-bottom: 19px;
  font-size: 14px;
  line-height: 1.57;
  text-transform: capitalize;
}
.team-box .social {
  display: flex;
  justify-content: center;
}
.team-box .social li {
  margin-right: 12px;
}
.team-box .social li a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  background: #5e84ff;
  border-radius: 8px;
  color: #fff;
  font-size: 14px;
  color: #000;
}
.team-box .social li a:hover {
  background: #000;
  color: #fff;
}

@media only screen and (max-width: 1234px) {
  .team-box {
    margin-left: 400px;
  }
}

@media only screen and (max-width: 1000px) {
  .team-box {
    margin-left: 300px;
  }
}
@media only screen and (max-width: 830px) {
  .team-box {
    margin-left: 200px;
  }
}
@media only screen and (max-width: 580px) {
  .team-box {
    margin-left: 30px;
  }
}
/*--------------------footer----------------------------- */

.widget-nav-menu li {
  display: inline-block;
}
.widget-nav-menu li a {
  font-size: 17px;
  color: #ffffff;
  padding-right: 45px;
  letter-spacing: 1.3px;
}
.widget-nav-menu li:last-child a {
  padding-right: 0;
}
.bottom {
  padding: 45px 0 82px 0;
}
#bottom .socials-list {
  text-align: right;
  padding-right: 45px;
}
#bottom .copyright {
  font-size: 16px;
  color: #ffffff;
}
#bottom .socials-list a {
  padding-left: 17px;
}
#bottom .socials-list i:hover {
  -webkit-animation-name: hvr-icon-pulse-grow;
  animation-name: hvr-icon-pulse-grow;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
}
.border-bottom {
  border-bottom: 1px solid #fff;
}
.footer-section {
  background: url("../../public/images/backgrounds/footer.png");
  background-position: center center;
  position: relative;
}

/*--------------------mainnav-mobi----------------------------- */

#mainnav-mobi {
  position: fixed;
  width: 300px;
  height: 100%;
  left: -100%;
  top: 0;
  background: #212529;
  z-index: 99999;
  overflow-y: auto;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

#mainnav-mobi.active {
  left: 0;
}
#mainnav-mobi ul li {
  margin: 0;
  position: relative;
  text-align: left;
  border-top: 1px solid rgba(255, 255, 255, 0.07);
  cursor: pointer;
}

#mainnav-mobi ul > li > a {
  padding: 0 0;
  line-height: 60px;
  padding-left: 35px;
}

#mainnav-mobi ul.sub-menu {
  padding-left: 0;
}

#mainnav-mobi ul > li.logo-mobi {
  padding: 25px 35px;
  line-height: unset;
  height: unset;
}

#mainnav-mobi ul > li.logo-mobi a {
  padding-left: 0;
}

#mainnav-mobi ul li a {
  color: rgba(255, 255, 255, 0.6);
  display: block;
}

#mainnav-mobi ul li .btn-submenu.active,
#mainnav-mobi ul > li.active > a,
#mainnav-mobi ul li a.active {
  text-decoration: underline;
  text-underline-position: under;
  color: #627fff;
}

#mainnav-mobi ul.sub-menu {
  padding-left: 0px;
  background: #272d32;
}
#mainnav-mobi ul.sub-menu {
  background: #2f363c;
}
/*--------------scroll-top----------------------------- */
#scroll-top.show {
  opacity: 1;
  visibility: visible;
  display: block;
}
#scroll-top {
  position: fixed;
  display: none;
  width: 35px;
  height: 35px;
  line-height: 35px;
  border-radius: 50%;
  text-align: center;
  z-index: 9;
  right: 30px;
  bottom: 30px;
  opacity: 0;
  visibility: hidden;
  cursor: pointer;
  overflow: hidden;
  background: #fff;
  background-color: lightblue;
  animation: back-to-top-bounce 2s infinite ease-in-out;
}
#scroll-top:after {
  content: "\f106";
  font-family: "Fontawesome";
  font-size: 20px;
  color: #000;
}

img[alt="Marketplace"] {
  position: relative;
  max-width: 150px;
  padding: 10px;
  align-items: center;
  justify-content: center;
}

img[alt="Github"] {
  position: relative;
  max-width: 100px;
  padding: 10px;
  align-items: center;
  justify-content: center;
}

/*--------------page-not-found----------------------------- */
.bg-dark {
  background-color: #343a40 !important;
}

.error-box .error-body {
  padding-top: 5%;
}
.error-box .error-title {
  font-size: 210px;
  font-weight: 900;
  text-shadow: 4px 4px 0 #fff, 6px 6px 0 #343a40;
  line-height: 210px;
}

.error-box .error-body {
  padding-top: 5%;
}

.text-danger {
  color: #f62d51 !important;
}
a.text-danger:hover,
a.text-danger:focus {
  color: #e60a31 !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover,
a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.text-muted {
  color: #a1aab2 !important;
}

.dark-body {
  color: #320654;
  background-color: #11181e;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  line-height: 1.7;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*--- ---*/
.about-us-section {
  min-height: 714px;
  margin-top: -100px;
  padding-bottom: 100px;
  background: url("../../public/images/backgrounds/main.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.about-us-section .about_us_content {
  position: relative;
}

.about-us-section .about_us_text {
  position: relative;
  max-width: 770px;
  margin: 46px auto auto auto;
  z-index: 2;
}

.about-us-section .about_us_text p {
  font-weight: 500;
  font-size: 22px;
  line-height: 38px;
  text-align: justify;
  color: rgba(255, 255, 255, 0.8);
  margin: 0px;
}
.about-us-section .about_us_text p + p {
  margin: 20px 0;
}

.about-us-section .about_us_img {
  position: absolute;
  top: 10px;
}
.about-us-section .about_us_img_left {
  top: 80px;
  left: -260px;
  width: 400px;
}
.about-us-section .about_us_img_right {
  top: 70px;
  right: -270px;
  width: 400px;
}

@media only screen and (max-width: 575px) {
  .about-us-section .about_us_text p {
    font-size: 13px;
    line-height: 25px;
    margin-bottom: 12px;
  }
}
.img-box {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  position: relative;
  padding: 20px 0 0 0;
  margin: 30px 10px 30px;
  background: #000;
  width: 100%;
  height: 100%;
}
.img-box::after {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  position: absolute;
  content: "";
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}
.img-box::before {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  position: absolute;
  content: "";
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.features-items {
  padding: 0;
}

.feature {
  height: 320px;
  width: 320px;
}
.twentytwenty-container img {
  height: 175px;
}

/* --------------------------features--------------------------------- */

.features-section {
  padding-top: 132px;
  padding-bottom: 146px;
  position: relative;
  overflow: hidden;
  padding-bottom: 0px;
  background: url("../../public/images/backgrounds/main.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1;
  min-height: 914px;
  padding-bottom: 100px;
}

/*--- Download Section ---*/

.main-btn {
  display: inline-block;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid blue;
  padding: 0 30px;
  font-size: 15px;
  line-height: 45px;
  border-radius: 6px;
  color: #fff;
  cursor: pointer;
  z-index: 5;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
  background-color: blue;
}
.main-btn:hover {
  background-color: #fff;
  color: blue;
  border-color: blue;
}
.main-btn.main-btn-2 {
  background-color: #fff;
  color: blue;
  border-color: blue;
}
.main-btn.main-btn-2:hover {
  background-color: blue;
  border-color: blue;
  color: #fff;
}

.disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.6;
}

.download-box {
  background: transparent;
  overflow: hidden;
  margin-bottom: 30px;
  max-height: 580px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .download-box {
    margin: 30px 0 0;
  }
}
@media (max-width: 767px) {
  .download-box {
    margin: 30px 0 0;
  }
}
.download-box .content {
  padding: 50px;
}
@media (max-width: 767px) {
  .download-box .content {
    padding: 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .download-box .content {
    padding: 50px;
  }
}
.download-box .content .title {
  font-size: 34px;
  font-weight: 700;
  margin-bottom: 8px;
}
.download-box .content a.main-btn {
  background: #fff;
  color: #0e1133;
  border-color: #fff;
}
.download-box .content a.main-btn:hover {
  background: blue;
  border-color: blue;
  color: #fff;
}
.download-box .content a.main-btn i {
  padding-right: 6px;
}
.download-box .content a.main-btn.main-btn-2 {
  background: blue;
  border-color: blue;
  color: #fff;
}
.download-box .content a.main-btn.main-btn-2:hover {
  background: #fff;
  color: #0e1133;
  border-color: #fff;
}
