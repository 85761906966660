/*------------------preloader--------------------------*/

.preloader {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background-color: #11181e;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  transform-origin: 0 0;
  transition: all 0.6s ease-out 0s;
}
.preloader-logo {
  text-align: center;
}
.preloader-progress {
  display: block;
  height: 3px;
  width: 100%;
  background-color: #474a4d;
  margin-top: 30px;
  position: relative;
  overflow: hidden;
}
.preloader-progress::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgb(117, 172, 255);
  transform-origin: 0 0;
  animation: loading 2s linear 0s 1 both;
}
.preloader .button {
  position: absolute;
  left: auto;
  top: auto;
  right: 15px;
  bottom: 15px;
  border: 0;
}

@keyframes loading {
  from {
    transform: scaleX(0);
  }

  to {
    transform: scaleX(1);
  }
}
