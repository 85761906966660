.container {
  max-width: 1200px;
}

/*---------------------------site-list----------------------------------- */
.site-list a {
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 25px;
  color: #ffffff;
  border-radius: 50%;
  display: inline-block;
}
.site-list .custom-bg-dark {
  background-color: #000;
}
.site-list .custom-bg-dark:hover {
  background-color: #fff;
}
