/*------------------base--------------------------*/
* {
  margin: 0;
  padding: 0;
}

html {
  margin: 0 !important;
  overflow: auto !important;
}

body {
  color: #320654;
  background-color: #f9f9ff;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  line-height: 1.7;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  max-width: 100%;
  height: auto;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.relative {
  position: relative;
}
/*------------------typography--------------------------*/
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #454360;
  font-family: "Rubik", sans-serif;
  font-weight: 700;
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 28px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 18px;
}

a {
  color: #fff;
  outline: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
a:hover {
  color: black;
  text-decoration: none;
}
a:focus {
  outline: 0;
}

blockquote {
  padding: 20px 20px;
  margin: 0 0 20px;
  font-size: 16px;
  background: #f7f7f7;
  border-radius: 10px;
}

blockquote p {
  line-height: 1.6;
}

pre {
  display: block;
  padding: 9.5px;
  margin: 0 0 10px;
  font-size: 13px;
  line-height: 1.42857143;
  color: #333;
  word-break: break-all;
  word-wrap: break-word;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 0;
}
